import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Box, Typography } from "@mui/material";

const DevicesBarChart = ({ data }) => {
    console.log(data);
  // Check if data is available
  if (!data) {
    return <Typography color="error">No data available for chart.</Typography>;
  }

  // Prepare the data to only include GB values
  const chartData = Object.keys(data).map((device) => ({
    device,
    gigabytes: data[device].gigabytes,
  }));
  console.log(chartData);
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Device Data Usage (in GB)
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="device" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="gigabytes" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DevicesBarChart;