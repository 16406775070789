import axios from 'axios';
import { BaseUrl, ConfigRule, PlaybookData, SocMappedUser } from '../constants/constants';

const user_details = JSON.parse(window.localStorage.getItem("user_details"));
let token = null;
if (user_details && user_details.data) {
  token = user_details.data.accessToken;
}

export const fetchPlaybookData = async (category = 'all', level = 'all') => {
    if (!token) {
        return;
    }

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseUrl + PlaybookData,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        data: JSON.stringify({
            category: category,
            level: level,
        }),
    };

    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const configRule = async (khika_rule_id) => {
    if (!token) {
        console.error('No token found. Please log in.');
        return;
    }

    const data = JSON.stringify({
      "khika_rule_id": khika_rule_id,
      "is_active": true
    });
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + ConfigRule,
      headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}` 
      },
      data: data
    };
    
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error in configuring rule:', error);
      throw error;
    }
};

export const fetchSocDashData = async ({ startTime, endTime, tenantName }) => {
  if (!token) {
      throw new Error('No token found. Please log in.');
  }

  const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BaseUrl}/user/soc_dash_data`,
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      data: JSON.stringify({
          startTime,
          endTime,
          tenantName,
      }),
  };

  try {
      const response = await axios(config);
      return response.data;
  } catch (error) {
      throw error;
  }
};

export const fetchSocDashWData = async (tenantNames) => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details") || "{}");
    const token = user_details?.data?.accessToken;

    if (!token) {
        throw new Error('No token found. Please log in.');
    }

    const data = JSON.stringify({
        tenantNames: tenantNames,
    });

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BaseUrl}/user/soc_dash_wdata`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        data: data,
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchSocDashLogData = async (tenantNames, dayRange) => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details") || "{}");
    const token = user_details?.data?.accessToken;

    if (!token) {
        throw new Error('No token found. Please log in.');
    }

    const data = JSON.stringify({
        tenantNames: tenantNames,
        dayRange: dayRange,
    });

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BaseUrl}/user/soc_dash_logdata`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        data: data,
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};
// export const SocMappedUserDetails = async () => {
//   if (!token) {
//       console.error('No token found. Please log in.');
//       return;
//   }

//   const config = {
//     method: 'post',
//     maxBodyLength: Infinity,
//     url: BaseUrl + SocMappedUser,
//     headers: { 
//         'Content-Type': 'application/json', 
//         'Authorization': `Bearer ${token}` 
//     },
//     data: ''
//   };
  
//   try {
//     const response = await axios(config);
//     return response.data;
//   } catch (error) {
//     console.error('Error in configuring rule:', error);
//     throw error;
//   }
// };