import React, { useState } from 'react'
import "react-perfect-scrollbar/dist/css/styles.css";
const SocHome = () => {

    const tenantname = window.localStorage.getItem("selectedtenant");
    const tenant_token = window.localStorage.getItem("tenant_token");
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const kibanaToken = user_details.data.kibana_token
    
    return (
        <>
            <iframe
                className='hide_menu'
                frameBorder="0"
                src={"https://devsaas.khika.in/kibana/app/wazuh?security_tenant=" + tenantname + "&embed=true&auth_token=" + tenant_token + "#/overview/?tabView=panels&tab=welcome"}
            />
        </>
    )
}

export default SocHome