import React, { useState } from 'react'
import Navbar from './Navbar'
import axios, * as others from 'axios';
import {
  Card, Box, Button, Typography, Checkbox, FormControlLabel, Menu, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Table,
  TableBody,
  TableRow,
  TableCell,
  CardContent,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { AlertsList, BaseUrl } from '../constants/constants';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CheckCircle } from '@mui/icons-material';

const Alerts2 = () => {
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewDetails = (alertData) => {
    setSelectedAlert(alertData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAlert(null);
  };

  const columns = [
    {
      field: 'timestamp',
      headerName: 'Date Time',
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'agent_name',
      headerName: 'Agent Name',
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'ruleid',
      headerName: 'Rule Id',
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'rule_info',
      headerName: 'Rule Info',
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'level',
      headerName: 'Level',
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'viewDetails',
      headerName: 'View Details',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: "2px 8px",
            fontSize: "12px",
            fontWeight: 500,
            borderRadius: "4px",
            textTransform: "none",
            minWidth: "80px",
          }}
          onClick={() => handleViewDetails(params.row)}
        >
          View Details
        </Button>
      ),
    },
  ];


  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken

  const now = new Date().toISOString();

  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [filtercount, setFilterCount] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);

  React.useEffect(() => {
    fetchData('');

  }, []);

  const [filterjson, setFilterJson] = useState(
    {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {
        "bool": {
          "filter":
            [
              // {"range": {"rule.level": {"gte": 8, "lt": 12}}}, 
              {
                "range": {
                  "timestamp": {
                    "gte": startdate,
                    "lt": enddate
                  }
                }
              }
            ]
        }
      },
      "aggs": {
        "rule_level_aggregation": {
          "terms": {
            "field": "rule.level",
            "size": 20
          }
        }
      }
    }
  );


  const fetchData = async page => {
    setLoading(true);
    var data = JSON.stringify(filterjson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList + page,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const [dropdownlist, setDropDownList] = useState([]);

  React.useEffect(() => {
    dropdownData('');

  }, []);

  const [dropdownjson, setDropdownJson] = useState(
    {
      "aggs": {
        "components": {
          "terms": { "field": "agent.name", "size": 1000 }
        }
      },
      "size": 0
    }
  );

  const dropdownData = async page => {

    var data = JSON.stringify(dropdownjson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList + page,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setDropDownList(response.data.aggregations.components.buckets);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const TodayButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };
    return <MenuItem onClick={handleClick}>Today</MenuItem>;
  };

  const ThisWeek = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const thisWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(thisWeek);
      setEndDate(now)
      updateTimeDate(thisWeek);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>This Week</MenuItem>;
  };

  const Last15MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last15Minutes = new Date(now.getTime() - 15 * 60 * 1000);
      setStartDate(last15Minutes);
      setEndDate(now)
      updateTimeDate(last15Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 15 Mins</MenuItem>;
  };

  const Last30MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Minutes = new Date(now.getTime() - 30 * 60 * 1000);
      setStartDate(last30Minutes);
      setEndDate(now)
      updateTimeDate(last30Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 30 Mins</MenuItem>;
  };

  const Last1Hour = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Hour = new Date(now.getTime() - 1 * 60 * 60 * 1000);
      setStartDate(last1Hour);
      setEndDate(now)
      updateTimeDate(last1Hour);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 1 Hour</MenuItem>;
  };

  const Last24Hours = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      setStartDate(last24Hours);
      setEndDate(now)
      updateTimeDate(last24Hours);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 24 Hours</MenuItem>;
  };

  const Last7DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 7 Days</MenuItem>;
  };

  const Last30DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      setStartDate(last30Days);
      setEndDate(now)
      updateTimeDate(last30Days);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 30 Days</MenuItem>;
  };

  const Last90DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last90Days = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
      setStartDate(last90Days);
      setEndDate(now)
      updateTimeDate(last90Days);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 90 Days</MenuItem>;
  };

  const Last1YearButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Year = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
      setStartDate(last1Year);
      setEndDate(now)
      updateTimeDate(last1Year);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 1 Year</MenuItem>;
  };


  const updateTimeDate = (start) => {
    const selected_location = location[0];

    const newJsonWithLoc = {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": [
            { "match_phrase": { "agent.name": selected_location } },
            { "range": { "timestamp": { "gte": start, "lt": enddate } } }
          ]
        }
      }
    };

    const newJsonWithoutLoc = {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": [
            { "range": { "timestamp": { "gte": start, "lt": enddate } } }
          ]
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  }


  const updateDate = () => {
    const selected_location = location[0];

    const newJsonWithLoc = {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": [
            { "match_phrase": { "agent.name": selected_location } },
            { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
          ]
        }
      }
    };

    const newJsonWithoutLoc = {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": [
            { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
          ]
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  }

  const resetDate = () => {
    const newJson = {
      // "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {
        "bool": {
          "filter":
            [
              {
                "range": {
                  "timestamp": {
                    "gte": startdate,
                    "lt": enddate
                  }
                }
              }
            ]
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFilterJson(newJson);
    setLocation([]);
    setStartDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    setEndDate(new Date().toISOString());
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = dropdownlist.map(item => (item.key))

  function getStyles(name, location, theme) {
    return {
      fontWeight:
        location.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();
  const [location, setLocation] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocation(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [levelgreaterthan12, setLevelGreaterThan12] = useState(false);
  const [levelbetween8to12, setLevelBetween8to12] = useState(false);
  const [levelbetween4to7, setLevelBetween4to7] = useState(false);
  const [levelbetween1to3, setLevelBetween1to3] = useState(false);
  const [levelequalto0, setLevelEqualto0] = useState(false);

  function handleCheckboxChange(event, levelType) {
    
    setLevelGreaterThan12(false);
    setLevelBetween8to12(false);
    setLevelBetween4to7(false);
    setLevelBetween1to3(false);
    setLevelEqualto0(false);

    
    switch (levelType) {
      case "greaterThan12":
        setLevelGreaterThan12(event.target.checked);
        break;
      case "between8to12":
        setLevelBetween8to12(event.target.checked);
        break;
      case "between4to7":
        setLevelBetween4to7(event.target.checked);
        break;
      case "between1to3":
        setLevelBetween1to3(event.target.checked);
        break;
      case "equalto0":
        setLevelEqualto0(event.target.checked);
        break;
      default:
        break;
    }

    if (event.target.checked) {
      let rangeFilter;

      switch (levelType) {
        case "greaterThan12":
          rangeFilter = { "gte": 11 };
          break;
        case "between8to12":
          rangeFilter = { "gte": 8, "lte": 10 };
          break;
        case "between4to7":
          rangeFilter = { "gte": 4, "lte": 7 };
          break;
        case "between1to3":
          rangeFilter = { "gte": 1, "lte": 3 };
          break;
        case "equalto0":
          rangeFilter = { "gte": 0, "lte": 0 };
          break;
        default:
          break;
      }

      const newJsonWithLoc = {
        "size": 1000,
        "sort": [{ "timestamp": "desc" }],
        "query": {
          "bool": {
            "filter": [
              { "range": { "rule.level": rangeFilter } },
              { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
            ]
          }
        }
      };

      setLoading(true);
      const data = JSON.stringify(newJsonWithLoc);

      const config = {
        method: 'post',
        url: BaseUrl + AlertsList,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setData(response.data.hits.hits.map(element => {
            const timestamp = new Date(element._source.timestamp).toLocaleString();
            return {
              id: element._source.id,
              agent_id: element._source.agent?.id || '',
              timestamp: timestamp,
              agent_name: element._source.agent?.name || '',
              description: element._source.rule?.description || '',
              level: element._source.rule?.level || '',
              ruleid: element._source.rule?.id || '',
              rule_info: element._source.rule?.mitre?.technique || '',
              agent_ip: element._source.agent?.ip || '',
              agent_os: element._source.agent?.labels?.os_type || '',
              eventID: element._source.data?.win?.system?.eventID || '',
              system_level: element._source.data?.win?.system?.level || ''
            };
          }));
          setTotalRows(response.data.hits.hits.length);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });

      setFilterJson(newJsonWithLoc);
    } else {
      resetDate();
    }
  }

  const getCountForKeys = (keys) => {
    return filtercount
      .filter(item => keys.includes(item.key))
      .reduce((acc, curr) => acc + curr.doc_count, 0);
  };

  const criticalCount = getCountForKeys([11, 12, 13, 14, 15]);
  const highCount = getCountForKeys([8, 9, 10]);
  const mediumCount = getCountForKeys([4, 5, 6, 7]);
  const lowCount = getCountForKeys([1, 2, 3]);
  const infoCount = getCountForKeys([0]);

  return (
    <div style={{ marginTop: '2%' }}>
      <div style={{ height: "7vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            flexWrap: "nowrap",
            background: "#f9f9f9",
            padding: 2,
            borderRadius: 2,
            boxShadow: 3,
            overflowX: "auto",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#f44336",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelgreaterthan12 } }, "greaterThan12")}
            >
              {levelgreaterthan12 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Critical ({criticalCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#ff9800",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween8to12 } }, "between8to12")}
            >
              {levelbetween8to12 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              High ({highCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#03a9f4",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween4to7 } }, "between4to7")}
            >
              {levelbetween4to7 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Medium ({mediumCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#8bc34a",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween1to3 } }, "between1to3")}
            >
              {levelbetween1to3 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Low ({lowCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#9e9e9e",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelequalto0 } }, "equalto0")}
            >
              {levelequalto0 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Info ({infoCount || 0})
            </Box>
          </Box>

          <FormControl sx={{ minWidth: 250 }} size="small">
            <InputLabel id="demo-multiple-chip-label">Agents</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={location}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Agents" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={handleClick}
            size="medium"
            sx={{ color: "#1976d2", borderColor: "#1976d2" }}
          >
            Quick Select
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TodayButton setStartDate={setStartDate} />
            <ThisWeek setStartDate={setStartDate} />
            <Last15MinsButton setStartDate={setStartDate} />
            <Last30MinsButton setStartDate={setStartDate} />
            <Last1Hour setStartDate={setStartDate} />
            <Last24Hours setStartDate={setStartDate} />
            <Last7DaysButton setStartDate={setStartDate} />
            <Last30DaysButton setStartDate={setStartDate} />
            <Last90DaysButton setStartDate={setStartDate} />
            <Last1YearButton setStartDate={setStartDate} />
          </Menu>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              onClick={updateDate}
              size="medium"
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              sx={{ backgroundColor: "#4caf50" }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              onClick={resetDate}
              size="medium"
              startIcon={<RefreshOutlinedIcon />}
              sx={{ color: "#f44336", borderColor: "#f44336" }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </div>
      <br />
      <Card>
        <Box style={{ height: 400, width: '100%' }}>
          <style>
            {`
              .MuiDataGrid-columnHeader {
                background-color: green;
                color: white;
              }
              .MuiDataGrid-cell {
                color: black;
              }
              .MuiDataGrid-footerContainer {
                background-color: lightgray;
              }
              .MuiDataGrid-row:nth-of-type(odd) {
                background-color: lightyellow;
              }
              .MuiDataGrid-row:nth-of-type(even) {
                background-color: white;
              }
            `}
          </style>
          <DataGrid
            rows={data}
            rowCount={totalRows}
            loading={loading}
            rowsPerPageOptions={[10]}
            rowHeight={30}
            pagination
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
          />
        </Box>
      </Card>

      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle>Alert Details</DialogTitle>
        <DialogContent>
          {selectedAlert ? (
            <Table
              sx={{
                '& .MuiTableCell-root': {
                  padding: '4px 8px', // Compact padding
                  fontSize: '12px',  // Smaller font size
                  border: '1px solid #ccc', // Add border to each cell

                },
                '& .MuiTableRow-root': {
                  height: '24px', // Reduce row height
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell><strong>Date Time:</strong></TableCell>
                  <TableCell>{selectedAlert.timestamp || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent ID:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_id || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent Name:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_name || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent IP:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_ip || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>OS Type:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_os || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Rule ID:</strong></TableCell>
                  <TableCell>{selectedAlert.ruleid || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Level:</strong></TableCell>
                  <TableCell>{selectedAlert.level || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Event ID:</strong></TableCell>
                  <TableCell>{selectedAlert.eventID || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Description:</strong></TableCell>
                  <TableCell>{selectedAlert.description || 'N/A'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <p>Loading...</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Alerts2;