import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Typography, Box } from "@mui/material";

const AgentsBarChart = ({ tenantNames, data }) => {
    const preparedData = tenantNames.map((tenant) => ({
        tenant,
        active: data && data[tenant] ? data[tenant]?.agents?.active || 0 : 0,
        inactive: data && data[tenant] ? data[tenant]?.agents?.inactive || 0 : 0,
    }));

    const maxValue = Math.max(
        ...preparedData.map((item) => item.active + item.inactive)
    );

    const ticks = Array.from({ length: Math.ceil(maxValue) + 1 }, (_, index) => index);

    return (
        <Box sx={{
            border: "2px solid #ddd",
            borderRadius: "8px",
            padding: "24px",
            backgroundColor: "#fff",
            position: "relative",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            mt: 4,
        }}>
            <Typography
                variant="h5"
                sx={{
                    position: "absolute",
                    top: "-14px",
                    left: "16px",
                    backgroundColor: "#fff",
                    padding: "0 12px",
                    fontWeight: "bold",
                    color: "#555",
                }}
            >
                Workstations Status
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={preparedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="tenant" />
                    <YAxis
                        ticks={ticks}
                    />
                    <Tooltip />
                    <Bar dataKey="active" fill="#4caf50" name="Active" />
                    <Bar dataKey="inactive" fill="#f44336" name="Inactive" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default AgentsBarChart;