import React, { useState } from "react";
import { Box, Drawer, List, ListItem, ListItemText, Collapse, Divider, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from "@mui/icons-material";

const Navbar2 = () => {
  const [open, setOpen] = useState(true); // Sidebar visibility
  const [submenuOpen, setSubmenuOpen] = useState({
    admin: false,
    dataInsights: false,
    alerts: false,
  });

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const handleMouseEnter = (menu) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [menu]: true,
    }));
  };

  const handleMouseLeave = (menu) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [menu]: false,
    }));
  };

  return (
    <Box display="flex">
      <style>
        {`
          .sidebar {
            width: ${open ? "240px" : "0"};
            transition: width 0.3s;
            overflow: hidden;
          }
          .toggle-button {
            position: absolute;
            top: 20px;
            left: 10px;
            z-index: 1300;
            background: #fff;
          }
          .main-content {
            margin-left: ${open ? "240px" : "0"};
            transition: margin-left 0.3s;
          }
          .submenu-item {
            padding-left: 32px;
          }
        `}
      </style>
      {/* Sidebar */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className="sidebar"
        PaperProps={{ className: "sidebar" }}
      >
        {/* Sidebar Content */}
        <Box>
          {/* Toggle Button (Inside Sidebar) */}
          <Box display="flex" justifyContent="flex-end" padding={1}>
            <IconButton onClick={toggleSidebar}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Divider />
          {/* Menu List */}
          <List>
            {/* Admin Menu */}
            <ListItem
              onMouseEnter={() => handleMouseEnter("admin")}
              onMouseLeave={() => handleMouseLeave("admin")}
            >
              <ListItemText primary="Admin" />
              {submenuOpen.admin ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={submenuOpen.admin} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className="submenu-item">
                  <ListItemText primary="Connectors" />
                </ListItem>
                <ListItem button className="submenu-item">
                  <ListItemText primary="Manage User" />
                </ListItem>
              </List>
            </Collapse>

            {/* Data Insights Menu */}
            <ListItem
              onMouseEnter={() => handleMouseEnter("dataInsights")}
              onMouseLeave={() => handleMouseLeave("dataInsights")}
            >
              <ListItemText primary="Data Insights" />
              {submenuOpen.dataInsights ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={submenuOpen.dataInsights} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className="submenu-item">
                  <ListItemText primary="CIS" />
                </ListItem>
                <ListItem button className="submenu-item">
                  <ListItemText primary="Threat Intelligence" />
                </ListItem>
              </List>
            </Collapse>

            {/* Alerts Menu */}
            <ListItem
              onMouseEnter={() => handleMouseEnter("alerts")}
              onMouseLeave={() => handleMouseLeave("alerts")}
            >
              <ListItemText primary="Alerts" />
              {submenuOpen.alerts ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={submenuOpen.alerts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className="submenu-item">
                  <ListItemText primary="Notifications" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>

      {/* Toggle Button (Outside Sidebar) */}
      {!open && (
        <IconButton onClick={toggleSidebar} className="toggle-button">
          <ChevronRight />
        </IconButton>
      )}

      {/* Main Content Placeholder */}
      <Box flex={1} padding={2} className="main-content">
        <h1>Main Content</h1>
      </Box>
    </Box>
  );
};

export default Navbar2;
