import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Chip, TextField, List, ListItem, ListItemText } from '@mui/material';

const Playbook = () => {
  const [alerts, setAlerts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('agent');
  const [searchTermCategory, setSearchTermCategory] = useState('');
  const [searchTermTitle, setSearchTermTitle] = useState('');

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then(data => {
        setAlerts(data.alert_id);
      });
  }, []);

  const filteredCategories = [...new Set(alerts.map(alert => alert.khika_rule_group))]
    .filter((category) => category.toLowerCase().includes(searchTermCategory.toLowerCase()));

  const filteredAlerts = alerts
    .filter(alert => alert.khika_rule_group === selectedCategory)
    .filter(alert => alert.description.toLowerCase().includes(searchTermTitle.toLowerCase()));

  const getCategoryCount = (category) => {
    return alerts.filter(alert => alert.khika_rule_group === category).length;
  };

  const capitalizeFirstLetter = (category) => {
    return category
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <Box display="flex" width="100%" height="100vh">
      <Box
        width="20%"
        height="80%"
        padding={2}
        sx={{ overflowY: 'auto', borderRight: '1px solid #ccc' }}
      >
        <TextField
          label="Search Categories"
          variant="outlined"
          fullWidth
          value={searchTermCategory}
          onChange={(e) => setSearchTermCategory(e.target.value)}
          margin="normal"
        />
        <List>
          {filteredCategories.map((category) => (
            <ListItem
              button
              key={category}
              onClick={() => setSelectedCategory(category)}
              selected={category === selectedCategory}
              sx={{
                backgroundColor: category === selectedCategory ? '#f0f0f0' : 'transparent',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>{capitalizeFirstLetter(category)}</Typography>
                    <Chip
                      label={getCategoryCount(category)}
                      color="secondary"
                      size="small"
                    />
                  </Box>
                }
                sx={{
                  fontWeight: category === selectedCategory ? 'bold' : 'normal',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        width="70%"
        height="100%"
        padding={2}
        sx={{ overflowY: 'auto' }}
      >
        <TextField
          label="Search Titles"
          variant="outlined"
          fullWidth
          value={searchTermTitle}
          onChange={(e) => setSearchTermTitle(e.target.value)}
          margin="normal"
        />
        <Grid container spacing={3}>
          {filteredAlerts.map((alert) => (
            <Grid item xs={12} sm={6} md={4} key={alert.khika_rule_id}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {alert.description}
                  </Typography>
                  <Chip
                    label={`IDs: ${alert.ids}`}
                    color="primary"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Playbook;