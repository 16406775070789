import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Grid } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import axios from "axios";
import { BaseUrl } from "../../constants/constants";
import VulnerabilityPieChart from "./VulnerabilityPieChart";
import CisPieChart from "./CisPieChart";

const EventSeverityCount = ({ selectedTenants, timeRange, severityColors }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vulnerabilityData, setVulnerabilityData] = useState({});
    const [cisData, setCisData] = useState({});

    useEffect(() => {
        const user_details = JSON.parse(window.localStorage.getItem("user_details") || "{}");
        const token = user_details?.data?.accessToken;

        if (!token) {
            setError("User not authenticated");
            setLoading(false);
            return;
        }

        const calculateTimeRange = () => {
            const now = new Date();
            let startTime;

            switch (timeRange) {
                case "24h":
                    startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
                    break;
                case "48h":
                    startTime = new Date(now.getTime() - 48 * 60 * 60 * 1000);
                    break;
                case "7d":
                    startTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                    break;
                default:
                    startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            }

            return {
                startTime: startTime.toISOString(),
                endTime: now.toISOString(),
            };
        };

        const fetchData = async () => {
            setLoading(true);
            try {
                const { startTime, endTime } = calculateTimeRange();

                const response = await axios.post(
                    `${BaseUrl}/user/soc_dash_data`,
                    { startTime, endTime, tenantName: selectedTenants },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const alertsCountData = response?.data?.alertsCountData || {};
                const vulnerabilityCountData = response?.data?.vulnerabilityCountData || {};
                const cisData = response?.data?.cisCountData || {};
                
                const filteredData = Object.keys(alertsCountData)
                    .filter((key) => selectedTenants.includes(key))
                    .map((key) => ({
                        company: key,
                        ...alertsCountData[key],
                    }));

                setVulnerabilityData(vulnerabilityCountData);
                setCisData(cisData);
                setData(filteredData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load data");
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedTenants, timeRange]);

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" align="center">
                {error}
            </Typography>
        );
    }
    
    if (!data.length) {
        return (
            <Typography align="center" color="text.secondary">
                No alerts data available for the selected time range.
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "24px",
                backgroundColor: "#fff",
                position: "relative",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                mb: 4,
                height: "420px"
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    position: "absolute",
                    top: "-14px",
                    left: "16px",
                    backgroundColor: "#fff",
                    padding: "0 12px",
                    fontWeight: "bold",
                    color: "#555",
                }}
            >
                Total Alerts
            </Typography>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="company"
                        label={{
                            value: "Tenant",
                            position: "bottom",
                            offset: 10,
                            style: { fontWeight: "bold", fontSize: 14 }
                        }}
                    />
                    <YAxis tickFormatter={(value) => value.toLocaleString()}
                        label={{
                            value: "Count",
                            angle: -90,
                            offset: -10,
                            position: "insideLeft",
                            style: { fontWeight: "bold", fontSize: 14 }
                        }}
                    />
                    <Tooltip />
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="top"
                        wrapperStyle={{
                            paddingLeft: "40px",
                        }}
                    />


                    {Object.keys(severityColors).map((severity) => (
                        <Bar
                            key={severity}
                            dataKey={severity}
                            stackId="a"
                            fill={severityColors[severity]}
                            name={severity.charAt(0).toUpperCase() + severity.slice(1)}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>

            <Grid container spacing={4} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                    <VulnerabilityPieChart vulnerabilityData={vulnerabilityData} />
                </Grid>
                <Grid item xs={6}>
                    <CisPieChart cisData={cisData} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default EventSeverityCount;