import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Typography, Link } from '@mui/material';
import axios from 'axios';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import logo from '../images/khikalogo.png';
import { BaseUrl, TenantDetails } from '../constants/constants';
import useFullPageLoader from './hooks/useFullPageLoader';

const NewLogin = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleLogin = () => {
    showLoader();
    const data = JSON.stringify({
      email_address: email,
      password: password,
    });
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/auth/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          window.localStorage.setItem("user_details", JSON.stringify(response));
          
          const tenantConfig = {
            method: 'get',
            url: BaseUrl + TenantDetails,
            headers: {
              'Authorization': `Bearer ${response.data.accessToken}`,
            },
          };
  
          axios(tenantConfig)
            .then((tenantResponse) => {
              const role = tenantResponse.data.authentication.role;
  
              window.localStorage.setItem("tenant_details", JSON.stringify(tenantResponse.data));
              window.localStorage.setItem("tenant_name", JSON.stringify(tenantResponse.data.tenant_name));
  
              if (role === 'user_soc') {
                window.location.href = '/dashboard/soc';
              } else {
                window.location.href = '/dashboard/home';
              }
            })
            .catch((error) => {
              hideLoader();
              swal({
                title: error.response.data.non_field_errors,
                text: "Please provide correct email and password",
                icon: "error",
              });
            });
        }
      })
      .catch((error) => {
        hideLoader();
        swal({
          title: error.response.data.non_field_errors,
          text: "Please provide correct email and password",
          icon: "error",
        });
      });
  };  

  return (
    <>
      <img className="wave" src={wave1} alt="Wave Background" />
      <div className="container-login">
        <div className="img">
          <img className="login-image" src={login} alt="Login Illustration" />
        </div>
        <div className="login-content">
          <form className="form-class-login" onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
            <div className="row">
              <div className="col">
                <img className="actionate-img" src={logo} width="90%" alt="Logo" />
              </div>
            </div>
            <div className="row">
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="row">
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    By logging in, you agree to the{' '}
                    <Link href="/Khika-Privacy-Policy.pdf" underline="always" color="primary" target='_blank'>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link href="/Khika-License-Terms-Conditions 1.pdf" underline="always" color="primary" target='_blank'>
                      License Terms & Conditions
                    </Link>.
                  </Typography>
                }
              />
            </div>
            <div className="row">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '20px' }}
                disabled={!checked}
                type="submit"
              >
                Login
              </Button>
            </div>
            <div className="row" style={{ marginTop: '10px', textAlign: 'center' }}>
              <Link href="/resetpassword" variant="body2">
                Forgot Password?
              </Link>
              <span style={{ margin: '0 10px' }}>|</span>
              <Link href="/signup" variant="body2">
                Register User
              </Link>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </>
  );
};

export default NewLogin;