import React, { useState } from 'react';
import Notifications1 from './Notifications1';
import { Typography, Tabs, Tab, Box } from '@mui/material';
import AlertMapping from './AlertMapping';
import Alertsmapping from './Alertsmapping';
import Playbook1 from './Playbook1';

const Notification_Channel = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="notification tabs">
        <Tab label="Alerts Mapping" />
        <Tab label="Notification Channels" />
        <Tab label="Alerts Playbook" />
      </Tabs>

      <Box sx={{ padding: '20px' }}>
        {selectedTab === 2 && (
            <Playbook1 setSelectedTab={setSelectedTab} />
        )}
        {selectedTab === 0 && (
            <Alertsmapping />
        )}
        {selectedTab === 1 && (
            <Notifications1 />
        )}
      </Box>
    </>
  );
};

export default Notification_Channel;