import { AppBar, Box, Button, MenuItem, Toolbar, Typography, Popper, Paper, ClickAwayListener } from '@mui/material';
import * as React from 'react';
import logo from '../images/khikalogo.png';
import { Link } from "react-router-dom";
import AccountPopover from '../components/AccountPopover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Navbar = ({ user }) => {
  const [anchorElModules, setAnchorElModules] = React.useState(null);
  const [subMenuAnchor, setSubMenuAnchor] = React.useState({});
  const [anchorElDataInsights, setAnchorElDataInsights] = React.useState(null);
  const [anchorElReports, setAnchorElReports] = React.useState(null);

  const handleMenuOpen = (setAnchor) => (event) => {
    setAnchorElModules(null);
    setAnchorElDataInsights(null);
    setAnchorElReports(null);
    setSubMenuAnchor({});
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor) => () => {
    setAnchor(null);
  };

  const handleSubMenuOpen = (key, event) => {
    setSubMenuAnchor((prev) => ({
      ...Object.keys(prev).reduce((acc, k) => ({ ...acc, [k]: null }), {}),
      [key]: event.currentTarget,
    }));
  };

  const handleSubMenuClose = (key) => {
    setSubMenuAnchor((prev) => ({
      ...prev,
      [key]: null,
    }));
  };

  const renderMenu = (anchorEl, menuItems, closeHandler) => (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-start"
      disableInteractive
      style={{
        zIndex: 1300,
        marginTop: 8,
      }}
    >
      <ClickAwayListener onClickAway={closeHandler}>
        <Paper>{menuItems}</Paper>
      </ClickAwayListener>
    </Popper>
  );

  const renderSubMenu = (key, subMenuItems, closeHandler) => (
    <Popper
      open={Boolean(subMenuAnchor[key])}
      anchorEl={subMenuAnchor[key]}
      placement="right-start"
      disableInteractive
      style={{
        zIndex: 1301,
        marginTop: 0,
        marginLeft: 8,
      }}
    >
      <ClickAwayListener onClickAway={() => handleSubMenuClose(key)}>
        <Paper>{subMenuItems}</Paper>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <AppBar style={{ background: 'white', zIndex: 1100 }} position="sticky" sx={{ flexGrow: 1 }}>
      <Toolbar>
        <Typography sx={{ flexGrow: 1 }}>
          <img src={logo} className="headers-logo" alt="logo" sx={{ flexGrow: 1 }} />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1.5, marginLeft: 'auto', paddingRight: 2 }}>
          <Button component={Link} to="/dashboard/home" sx={{ color: 'black', textTransform: 'none' }}>Home</Button>
          <Button component={Link} to="/dashboard/connectors" sx={{ color: 'black', textTransform: 'none' }}>Connectors</Button>
          <Button component={Link} to="/dashboard/alerts" sx={{ color: 'black', textTransform: 'none' }}>Alerts</Button>
          <Button component={Link} to="/dashboard/cis" sx={{ color: 'black', textTransform: 'none' }}>CIS</Button>
          <Button component={Link} to="/dashboard/manageusers" sx={{ color: 'black', textTransform: 'none' }}>Manage Users</Button>

          <Button sx={{ color: 'black', textTransform: 'none' }} onClick={handleMenuOpen(setAnchorElModules)}>
            Modules<ExpandMoreIcon />
          </Button>
          {renderMenu(anchorElModules, (
            <>
              <MenuItem onClick={(e) => handleSubMenuOpen('securityInfo', e)} sx={{ color: 'black' }}>
                Security Information Management<ChevronRightIcon />
              </MenuItem>
              {renderSubMenu('securityInfo', (
                <>
                  <MenuItem component={Link} to="/dashboard/modules/security-management/security-event" sx={{ color: 'black' }}>Security Events</MenuItem>
                  <MenuItem component={Link} to="/dashboard/modules/security-management/integrity-monitoring" sx={{ color: 'black' }}>Integrity Monitoring</MenuItem>
                </>
              ))}

              <MenuItem onClick={(e) => handleSubMenuOpen('auditingPolicy', e)} sx={{ color: 'black' }}>
                Auditing and Policy Monitoring<ChevronRightIcon />
              </MenuItem>
              {renderSubMenu('auditingPolicy', (
                <>
                  <MenuItem component={Link} to="/dashboard/modules/auditing-policy-monitoring/policy-monitoring" sx={{ color: 'black' }}>Policy Monitoring</MenuItem>
                  <MenuItem component={Link} to="/dashboard/modules/auditing-policy-monitoring/system-auditing" sx={{ color: 'black' }}>System Auditing</MenuItem>
                </>
              ))}

              <MenuItem onClick={(e) => handleSubMenuOpen('threatDetection', e)} sx={{ color: 'black' }}>
                Threat Detection and Response<ChevronRightIcon />
              </MenuItem>
              {renderSubMenu('threatDetection', (
                <>
                  <MenuItem component={Link} to="/dashboard/modules/threat-detection/vulnerabilities" sx={{ color: 'black' }}>Vulnerabilities</MenuItem>
                  <MenuItem component={Link} to="/dashboard/modules/threat-detection/mitre-attack" sx={{ color: 'black' }}>MITRE ATT&CK</MenuItem>
                </>
              ))}

              <MenuItem onClick={(e) => handleSubMenuOpen('regulatoryCompliance', e)} sx={{ color: 'black' }}>
                Regulatory Compliance<ChevronRightIcon />
              </MenuItem>
              {renderSubMenu('regulatoryCompliance', (
                <>
                  <MenuItem component={Link} to="/dashboard/modules/regulatory-compliance/pci-dss" sx={{ color: 'black' }}>PCI DSS</MenuItem>
                  <MenuItem component={Link} to="/dashboard/modules/regulatory-compliance/nist-800-53" sx={{ color: 'black' }}>NIST 800-53</MenuItem>
                  <MenuItem component={Link} to="/modules/tsc" sx={{ color: 'black' }}>TSC</MenuItem>
                  <MenuItem component={Link} to="/modules/gdpr" sx={{ color: 'black' }}>GDPR</MenuItem>
                  <MenuItem component={Link} to="/modules/hipaa" sx={{ color: 'black' }}>HIPAA</MenuItem>
                </>
              ))}
            </>
          ), handleMenuClose(setAnchorElModules))}

          <Button sx={{ color: 'black', textTransform: 'none' }} onClick={handleMenuOpen(setAnchorElDataInsights)}>
            Data Insights<ExpandMoreIcon />
          </Button>
          {renderMenu(anchorElDataInsights, (
            <>
              <MenuItem component={Link} to="/data-insights/alerts-data" sx={{ color: 'black' }}>Alerts Data</MenuItem>
              <MenuItem component={Link} to="/data-insights/archive-data" sx={{ color: 'black' }}>Archive Data</MenuItem>
            </>
          ), handleMenuClose(setAnchorElDataInsights))}

          <Button sx={{ color: 'black', textTransform: 'none' }} onClick={handleMenuOpen(setAnchorElReports)}>
            Reports<ExpandMoreIcon />
          </Button>
          {renderMenu(anchorElReports, (
            <>
              <MenuItem component={Link} to="/reports/module-reports" sx={{ color: 'black' }}>Module Reports</MenuItem>
              <MenuItem component={Link} to="/reports/general-reports" sx={{ color: 'black' }}>General Reports</MenuItem>
            </>
          ), handleMenuClose(setAnchorElReports))}
        </Box>
        <AccountPopover UserData={user} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
