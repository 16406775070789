import { Box, Typography } from "@mui/material";
import React from "react";
import { PieChart, Pie, Cell, Tooltip as PieTooltip, Legend as PieLegend, ResponsiveContainer } from "recharts";

const VulnerabilityPieChart = ({ vulnerabilityData }) => {
    const pieData = Object.keys(vulnerabilityData).map(key => ({
        name: key,
        value: vulnerabilityData[key],
    }));

    const COLORS = [
        '#ff8c00', '#00c49f', '#ff6347', '#8a2be2', '#dc143c', '#00bfff', '#ff1493', '#20b2aa', '#ff7f50', '#daa520',
        '#adff2f', '#ff4500', '#2e8b57', '#6495ed', '#d2691e', '#ff6347', '#ff8c00', '#9acd32', '#f4a300', '#f0e68c',
        '#8b0000', '#008080', '#b0c4de', '#c71585', '#ffd700', '#ff69b4', '#ff1493', '#f08080', '#dcdcdc', '#90ee90',
        '#ff00ff', '#dda0dd', '#cd5c5c', '#ffc0cb', '#ffb6c1', '#f0e68c', '#32cd32', '#98fb98', '#8b4513', '#2f4f4f',
        '#9acd32', '#6a5acd', '#20b2aa', '#b22222', '#deb887', '#a52a2a', '#5f9ea0', '#66cdaa', '#ff4500', '#ffdab9'
    ];      

    return (
        <Box
        sx={{
            border: "2px solid #ddd",
            borderRadius: "8px",
            padding: "24px",
            backgroundColor: "#fff",
            position: "relative",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            mb: 4,
            ml: -3,
            height: "350px",
            width: "105%"
        }}
        >
            <Typography
                variant="h5"
                sx={{
                    position: "absolute",
                    top: "-14px",
                    left: "16px",
                    backgroundColor: "#fff",
                    padding: "0 12px",
                    fontWeight: "bold",
                    color: "#555",
                }}
            >
                Vulnerability Count
            </Typography>
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8">
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <PieTooltip />
                <PieLegend 
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    wrapperStyle={{
                        paddingLeft: "40px",
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
        </Box>
    );
};

export default VulnerabilityPieChart;