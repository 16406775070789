import * as React from 'react';
import Box from '@mui/material/Box';
import { Container, Grid, Typography, Avatar, Stack, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { getUserDetails } from '../service/UserDetails';
import { useEffect, useState } from 'react';
import { getTenantDetails } from '../service/TenantDetails';
import dayjs from 'dayjs';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArchiveIcon from '@mui/icons-material/Archive';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import InfoIcon from '@mui/icons-material/Info';

export default function Profile() {
  const [userDetails, setUserDetails] = useState();
  const [tenantDetails, setTenantDetails] = useState();
  const [apiCall, setApiCall] = useState(false);

  useEffect(() => {
    setApiCall(true);
    getUserDetails()
      .then((resp) => {
        setUserDetails(resp);
        setApiCall(false);
      });
  }, []);

  useEffect(() => {
    setApiCall(true);
    getTenantDetails()
      .then((resp) => {
        setTenantDetails(resp);
        setApiCall(false);
      });
  }, []);

  const formatDateTime = (isoString) => {
    return dayjs(isoString).format('DD/MM/YYYY HH:mm');
  };

  const calculateExpiryDate = (activationDate, validityPeriod) => {
    if (!activationDate || !validityPeriod) return '-';
    return dayjs(activationDate).add(validityPeriod, 'day').format('DD/MM/YYYY');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        padding: 3,
      }}
    >
      <Container
        maxWidth="md" 
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: 6,
          padding: 3,
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
          animation: 'fadeIn 1s ease-out',
        }}
      >
        {apiCall ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Skeleton variant="circular" width={100} height={100} />
            <Skeleton width="70%" sx={{ mt: 2 }} />
            <Skeleton width="80%" sx={{ mt: 1 }} />
            <Skeleton width="90%" sx={{ mt: 1 }} />
          </Box>
        ) : (
          <Stack direction="column" spacing={3} alignItems="center">
            <Avatar
              sx={{
                width: 100,
                height: 100,
                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                border: '4px solid #ffffff',
              }}
              src={userDetails?.profile_picture || '/default-avatar.png'}
            />
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: '#2c3e50', textAlign: 'center', letterSpacing: 0.5 }}
            >
              {userDetails?.first_name} {userDetails?.last_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#7f8c8d',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
            >
              {userDetails?.email}
            </Typography>

            <Box sx={{ width: '100%' }}>
              <Grid container spacing={4} justifyContent="space-evenly">
                {[ 
                  { icon: <BusinessIcon color="primary" />, label: 'Company', value: userDetails?.company_name },
                  { icon: <PhoneIcon color="primary" />, label: 'Phone', value: userDetails?.phone },
                  {
                    icon: <CalendarTodayIcon color="primary" />,
                    label: 'Expiry Date',
                    value: calculateExpiryDate(tenantDetails?.activation_date, tenantDetails?.validity_period),
                  },
                  {
                    icon: <ArchiveIcon color="primary" />,
                    label: 'Archive Retention Days',
                    value: tenantDetails?.archive_retention_days,
                  },
                  {
                    icon: <LiveTvIcon color="primary" />,
                    label: 'Live Retention Days',
                    value: tenantDetails?.live_retention_days,
                  },
                  {
                    icon: <InfoIcon color="primary" />,
                    label: 'Subscription Name',
                    value: tenantDetails?.subscription_name,
                  },
                ].map((item, index) => (
                  <Grid item xs={4} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    {item.icon}
                    <Box sx={{ ml: 2 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#34495e',
                          fontWeight: 600,
                          fontSize: '0.95rem',
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#7f8c8d', fontWeight: 500 }}>
                        {item.value || '-'}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Divider
              sx={{
                width: '100%',
                borderColor: '#ecf0f1',
                borderWidth: 1,
                mt: 3,
                mb: 2,
              }}
            />
            <Typography
              variant="caption"
              sx={{ color: '#bdc3c7', textAlign: 'center' }}
            >
              Data fetched from the server. Last updated {dayjs().format('DD/MM/YYYY HH:mm')}.
            </Typography>
          </Stack>
        )}
      </Container>
    </Box>
  );
}
