import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { MenuItem, Select, Checkbox, ListItemText, FormControl, Button, Switch, TextField, IconButton, InputAdornment, Tooltip, InputLabel } from '@mui/material';
import { BaseUrl } from '../constants/constants';
import swal from 'sweetalert';
import { Cancel, CancelOutlined, CheckCircleOutline, Clear } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

const Alertsmapping = () => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken;

    const [alerts, setAlerts] = useState([]);
    const [userConnectors, setUserConnectors] = useState({});
    const [loading, setLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState('');

    const fetchAlerts = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BaseUrl + '/alerts/rules',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.request(config);
            setAlerts(response.data.alert_rule_mapping);
            setUserConnectors(response.data.user_connectors);
        } catch (err) {
            
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    const filteredAlerts = alerts.filter((alert) =>
        (alert.khika_rule_title.toLowerCase().includes(searchQuery.toLowerCase()) || 
         alert.khika_rule_description.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (selectedCategory === '' || alert.khika_rule_group === selectedCategory)
    );
    
    const channelNames = Object.keys(userConnectors);

    const handleUpdate = async (row) => {
        const channelIds = row.channel_ids;
        const ruleIds = [row.ids];

        const data = JSON.stringify({
            "channel_ids": channelIds,
            "rule_ids": ruleIds
        });
        
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseUrl + '/notification-channel/update_ruleid_mapping',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            swal({
                title: "Alert Mapping Successful.",
                text: "",
                icon: "success",
              }).then(function() {
                fetchAlerts();
            });
        } catch (error) {
            
        }
    };

    const handleUpdateThrottledMins = async (row, newValue) => {
        const throttledMins = Number(newValue);

        const data = JSON.stringify({
          "khika_rule_id": row.khika_rule_id,
          "is_active": row.is_active,
          "throttled_mins": throttledMins,
        });
    
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseUrl + '/alerts/throttling',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
    
        try {
          const response = await axios.request(config);
          fetchAlerts();
        } catch (error) {
          
        }
      };

    const columns = [
        { field: 'khika_rule_title', headerName: 'Alert', flex: 1.3, cellClassName: 'word-wrap', headerAlign: 'center' },
        {
            field: 'channel_names',
            headerName: 'Channels',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const [selectedChannels, setSelectedChannels] = useState(params.value || []);
        
                const handleChange = async (event) => {
                    const value = event.target.value;
                    setSelectedChannels(value);
                    setAlerts((prevAlerts) =>
                        prevAlerts.map((alert) =>
                            alert.khika_rule_id === params.row.khika_rule_id
                                ? { 
                                    ...alert, 
                                    channel_names: value, 
                                    channel_ids: event.target.value.map((name) => userConnectors[name]?.channel_id) 
                                }
                                : alert
                        )
                    );
        
                    const channelIds = value.map((name) => userConnectors[name]?.channel_id);
                    const ruleIds = [params.row.ids];
        
                    const data = JSON.stringify({
                        channel_ids: channelIds,
                        rule_ids: ruleIds,
                    });
        
                    const config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: BaseUrl + '/notification-channel/update_ruleid_mapping',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        data: data,
                    };
        
                    try {
                        await axios.request(config);
                        fetchAlerts();
                    } catch (error) {
                        console.error("Failed to update alert mapping", error);
                    }
                };
        
                return (
                    <FormControl fullWidth>
                        <Select
                            multiple
                            value={selectedChannels}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150,
                                        width: 250,
                                    },
                                },
                            }}
                            sx={{ width: '100%', height: 20 }}
                        >
                            {channelNames.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    sx={{
                                    padding: '4px 8px',
                                    minHeight: '32px',
                                    lineHeight: '1.4',
                                    }}
                                >
                                    <Checkbox
                                    checked={selectedChannels.includes(name)}
                                    size="small"
                                    sx={{ padding: '2px' }}
                                    />
                                    <ListItemText
                                    primary={name}
                                    primaryTypographyProps={{
                                        variant: 'body2',
                                        style: { lineHeight: '1.4' },
                                    }}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            },
        },
        {
            field: 'khika_rule_description',
            headerName: 'Description',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
              <Tooltip title={params.value || 'No description available'} arrow>
                <IconButton>
                <InfoIcon 
                    sx={{ 
                        color: 'info.main',
                        fontSize: 30
                    }}
                />
                </IconButton>
              </Tooltip>
            ),
        },
        {
            field: 'status',
            headerName: 'Alert On/Off',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const [isActive, setIsActive] = useState(params.row.is_active);
    
                const handleToggle = (event) => {
                    const newStatus = event.target.checked;
                    setIsActive(newStatus);
    
                    const data = JSON.stringify({
                        "khika_rule_id": params.row.khika_rule_id,
                        "is_active": newStatus
                    });
    
                    const config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: BaseUrl + '/alerts/configure_rule',
                        headers: { 
                            'Content-Type': 'application/json', 
                            'Authorization': `Bearer ${token}`,
                        },
                        data: data
                    };
    
                    axios.request(config)
                        .then((response) => {
                            fetchAlerts();
                        })
                        .catch((error) => {
                            
                        });
                };

                const handleConfigure = () => {
                    const data = JSON.stringify({
                        "khika_rule_id": params.row.khika_rule_id,
                        "is_active": true
                    });
    
                    const config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: BaseUrl + '/alerts/configure_rule',
                        headers: { 
                            'Content-Type': 'application/json', 
                            'Authorization': `Bearer ${token}`,
                        },
                        data: data
                    };
    
                    axios.request(config)
                        .then((response) => {
                            window.location.reload();
                        })
                        .catch((error) => {
                            
                        });
                };
    
                if (isActive === undefined) {
                    return (
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleConfigure()}
                            sx={{ height: '20px' }}
                        >
                            Configure
                        </Button>
                    );
                }
    
                return (
                    <Switch
                        checked={isActive}
                        onChange={handleToggle}
                        color="primary"
                    />
                );
            },
        },
        {
            field: 'throttling',
            headerName: 'Throttling (in mins)',
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const [isActive, setIsActive] = useState(params.row.is_throttling_active);
                const [isEditing, setIsEditing] = useState(false);
                const [newThrottledMins, setNewThrottledMins] = useState(params.row.throttled_mins || "");
        
                const handleToggle = (event) => {
                    const newStatus = event.target.checked;
                    setIsActive(newStatus);
        
                    const data = JSON.stringify({
                        khika_rule_id: params.row.khika_rule_id,
                        is_active: newStatus,
                        throttled_mins: Number(params.row.throttled_mins),
                    });
        
                    const config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: BaseUrl + '/alerts/throttling',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        data: data,
                    };
        
                    axios.request(config)
                        .then((response) => {
                            fetchAlerts();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                };
        
                const handleEditClick = () => {
                    setIsEditing(true);
                };
        
                const handleSaveClick = () => {
                    const data = JSON.stringify({
                        khika_rule_id: params.row.khika_rule_id,
                        is_active: isActive,
                        throttled_mins: Number(newThrottledMins),
                    });
        
                    const config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: BaseUrl + '/alerts/throttling',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        data: data,
                    };
        
                    axios.request(config)
                        .then((response) => {
                            fetchAlerts();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
        
                    setIsEditing(false);
                };
        
                const handleCancelClick = () => {
                    setNewThrottledMins(params.row.throttled_mins || "");
                    setIsEditing(false);
                };
        
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <Switch
                            checked={isActive}
                            onChange={handleToggle}
                            color="primary"
                        />
                        {isEditing ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    value={newThrottledMins}
                                    onChange={(e) => setNewThrottledMins(e.target.value)}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: 50 }}
                                    InputProps={{
                                        style: {
                                            height: '20px',
                                        },
                                    }}
                                />
                                <IconButton onClick={handleCancelClick} color="error">
                                    <CancelOutlined />
                                </IconButton>
                                <IconButton onClick={handleSaveClick} color="primary">
                                    <CheckCircleOutline />
                                </IconButton>
                            </div>
                        ) : (
                            <>
                                <span>
                                    {params.row.throttled_mins != null ? params.row.throttled_mins : "-"}
                                </span>
                                <IconButton onClick={handleEditClick}>
                                    <EditIcon
                                    sx={{ 
                                        color: 'info.main',
                                        fontSize: 25
                                    }}
                                    />
                                    
                                </IconButton>
                            </>
                        )}
                    </div>
                );
            },
        }        
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '75vh', width: '100%' }}>
            <style>
                {`
                .MuiDataGrid-columnHeader {
                    background-color: green;
                    color: white;
                }
                .MuiDataGrid-cell {
                    color: black;
                }
                .MuiDataGrid-footerContainer {
                    background-color: lightgray;
                }
                .MuiDataGrid-row:nth-of-type(odd) {
                    background-color: lightyellow;
                }
                .MuiDataGrid-row:nth-of-type(even) {
                    background-color: white;
                }
                `}
            </style>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <FormControl variant="outlined" size="small" sx={{ width: '200px' }}>
                <InputLabel>Category</InputLabel>
                <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Category"
                >
                    <MenuItem value="">All Categories</MenuItem>
                    {Object.entries(
                        alerts.reduce((acc, alert) => {
                            const category = alert.khika_rule_group || 'Uncategorized';
                            acc[category] = (acc[category] || 0) + 1;
                            return acc;
                        }, {})
                    ).map(([category, count]) => (
                        <MenuItem key={category} value={category}>
                            {category} ({count})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
                <TextField
                    label="Search Alerts"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ width: '300px' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchQuery && (
                                    <IconButton
                                        onClick={() => setSearchQuery('')}
                                        edge="end"
                                    >
                                        <Clear />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div style={{ flexGrow: 1 }}>
                <DataGrid
                rows={filteredAlerts}
                columns={columns}
                pageSize={50}
                getRowId={(row) => row.khika_rule_id}
                rowHeight={35}
                disableSelectionOnClick
                disableColumnMenu
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                />
            </div>
        </div>
    );
};

export default Alertsmapping;